<template>
  <div class="main-content q-pa-md">
    <Mural
      :breadcrumbs="breadcrumbs"
      route="training"
      :query="{ id: courseId }"
    >
      <DefaultBtn
        titleButton="Salvar"
        icon="edit"
        @click="(event) => validateAndAddTraining(event)"
      />
    </Mural>
    <div class="content-new-training col-12">
      <div class="content-thumb-training col-4">
        <from class="col-12 flex row q-pa-sm">
          <div class="col-12">
            <label class="title-form">Capa</label>
          </div>
          <div class="col-12 thumb-training q-mt-sm shadow-3">
            <q-img
              height="150px"
              :src="imageUrl"
              fit="contain"
              style="background-color: #e5e5e5"
            />
          </div>
          <div class="col-12 btn-thumb-training flex justify-between q-mt-md">
            <q-btn
              @click="sendEventAddImage"
              class="q-mt-xs"
              unelevated
              color="default-pink"
              text-color="default-white"
              label="Escolher imagem"
            />
            <input
              style="display: none"
              ref="refFileInput"
              @change="onEventFilePicked"
              type="file"
              name="upload"
              accept="image/*"
            />

            <q-btn
              @click="sendEventRemoveImage"
              class="q-mt-xs"
              outline
              color="default-pink"
              label="Remover"
            />
          </div>
          <div class="col-12 row" v-show="false">
            <div class="col-12 q-mt-md">
              <label class="label-title-menus">Certificado</label>
            </div>
            <div class="col-12 flex justify-between">
              <label>Emitir</label>
              <q-toggle v-model="emitir" color="green" />
            </div>
          </div>
          <q-separator inset color="grey-5" />
          <div class="col-12 q-mt-md" v-show="false">
            <label class="label-title-menus">Gameficação</label>
          </div>
          <div class="col-12 row flex q-pb-sm" v-show="false">
            <div class="flex justify-center items-center">
              <label class="h5">Pontuação da atividade</label>
            </div>
            <q-input
              class="col-4 q-px-md"
              bg-color="grey-3"
              v-model="pontuacaoAtividade"
              color="default-pink"
              dense
              type="number"
              outlined
            />
          </div>
          <div class="col-12 row flex" v-show="false">
            <div class="flex justify-center items-center">
              <label class="h5">Pontuação da conclusão</label>
            </div>
            <q-input
              class="col-4 q-px-md"
              bg-color="grey-3"
              v-model="pontuacaoConclusao"
              color="default-pink"
              dense
              type="number"
              outlined
            />
          </div>
        </from>
      </div>
      <div class="content-form-training col-8">
        <form @submit.prevent.stop="onSubmit">
          <div class="data-inputs row col-12 q-pa-sm">
            <div class="form-input row col-2">
              <div class="row col-12 q-py-xs">
                <span class="title-input-form">Status</span>
              </div>
              <div>
                <q-toggle v-model="status" color="green" />
              </div>
            </div>
            <div class="form-input row col-3 q-mx-lg">
              <div class="row col-12 q-py-xs">
                <span class="title-input-form">Início</span>
              </div>
              <div class="row col-12">
                <q-input
                  class="col-12"
                  bg-color="grey-3"
                  v-model="training.start_date"
                  dense
                  type="date"
                  outlined
                  :rules="[
                    (val) => !!val || 'O campo Data Início é obrigatório.',
                    (val) =>
                      validateDate(val, 'start', null, training.end_date),
                  ]"
                />
              </div>
            </div>
            <div class="form-input row col-3">
              <div class="row col-12 q-py-xs">
                <span class="title-input-form">Fim</span>
              </div>
              <div class="row col-12">
                <q-input
                  class="col-12"
                  bg-color="grey-3"
                  v-model="training.end_date"
                  dense
                  type="date"
                  outlined
                  :rules="[
                    (val) => !!val || 'O campo Data Fim é obrigatório.',
                    (val) => validateDate(val, 'end', training.start_date, null),
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row q-py-xs">
                <span class="title-input-form">Nome do treinamento</span>
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  bg-color="grey-3"
                  v-model="training.training_title"
                  color="default-pink"
                  dense
                  type="text"
                  outlined
                  :rules="[
                    (val) =>
                      !!val || 'O campo Nome do treinamento é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>

          <!-- <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row q-py-xs">
                <span class="title-input-form">Categoria</span>
              </div>
              <div class="row">
                <q-input
                    class="col-7"
                    bg-color="grey-3"
                    v-model="training.category"
                    color="default-pink"
                    dense
                    type="text"
                    outlined

                    :rules="[(val) => !!val || 'O campo Categoria é obrigatório.']"
                /> 
                <q-select
                  outlined
                  bg-color="grey-3"
                  color="default-pink"
                  class="col-6"
                  dense
                  emit-value
                  map-options
                  v-model="training.category"
                  :options="listCategories"
                  :rules="[
                    (val) => !!val || 'O campo Categoria é obrigatório.',
                  ]"
                />
                <div class="col-4 q-ml-xl" style="height: 0">
                  <OutlineBtn
                    titleButton="Cadastrar categorias"
                    :showIcon="false"
                    @click="showDialogCategory = true"
                  />
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-4 column">
              <div class="row q-py-xs">
                <span class="title-input-form">Carga Horária (Horas)</span>
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  bg-color="grey-3"
                  v-model="training.workload_hour"
                  color="default-pink"
                  dense
                  type="number"
                  outlined
                  :rules="[
                    (val) => !!val || 'O campo Carga Horária é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>

          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row q-py-xs">
                <span class="title-input-form">Descrição</span>
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  bg-color="grey-3"
                  v-model="training.description"
                  color="default-pink"
                  dense
                  type="textarea"
                  outlined
                  :rules="[
                    (val) => !!val || 'O campo Descrição é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import InputNumber from "@/components/shared/inputs/InputNumber.vue";

import OutlineBtn from "@/components/shared/buttons/OutlineBtn.vue";

import { ref, reactive, onMounted } from "vue";
import TrailDataServices from "@/services/TrailDataServices";
import { useRouter, useRoute } from "vue-router";
import { ENV } from "@/utils/env";
import { date, useQuasar } from "quasar";

export default {
  name: "NewTraining",
  components: {
    Mural,
    DefaultBtn,
    InputNumber,
    OutlineBtn,
  },
  setup() {
    let breadcrumbs = ref(["Adicionar treinamento"]);
    const imageUrl = ref("");
    const file = ref(null);
    const refFileInput = ref(null);
    let status = ref(true);
    let emitir = ref(true);
    let loading = ref(false);
    let pontuacaoAtividade = ref(0);
    let pontuacaoConclusao = ref(0);

    let $q = new useQuasar();
    let route = new useRoute();
    let router = new useRouter();
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let trailDataServices = new TrailDataServices();
    let courseId = route.query.courseId;
    let training = reactive({
      training_title: null,
      description: null,
      start_date: null,
      end_date: null,
      color: "default",
      workload_hour: 0,
    });
    let listCategories = ref([]);
    let showDialogCategory = ref(false);

    const sendEventRemoveImage = () => {
      imageUrl.value = "";
      refFileInput.value = null;
      file.value = "";
    };

    const onEventFilePicked = (event) => {
      const files = event.target.files;
      file.value = files[0];
      const filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor adicione um arquivo válido");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (event) => {
        imageUrl.value = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    };
    const sendEventAddImage = () => {
      refFileInput.value.click();
    };

    onMounted(() => {
      GetTraining(route.query.courseId, route.query.trainingId);
      _getCategory();
    });

    async function _getCategory() {
      await trailDataServices
        .getCategories()
        .then((response) => {
          response.data.map((data) => {
            listCategories.value.push({
              label: data.name,
            });
          });
        })
        .catch((error) => {
          alertErro(error.response.data.toString());
        });
    }

    async function addNewCategory(newCategoryName, event) {
      event.preventDefault();
      //loading.value = true;

      if (newCategoryName == "") {
        _alertNotify("Você precisa preencher o campo nome da categoria");
        loading = false;
        return;
      }

      const formData = new FormData();
      formData.append("name", newCategoryName);

      await trailDataServices
        .addCategories(formData)
        .then(() => {
          listCategories.value.push({
            label: newCategoryName,
          });
        })
        .catch((error) => {
          alertErro(error.response.data.toString());
        });
      newCategoryName = "";
    }

    async function validateAndAddTraining(event) {
      event.preventDefault();
      loading.value = true;
      //console.log(training.category);

      const formData = new FormData();
      formData.append("file", file.value);
      formData.append("training_title", training.training_title);
      formData.append("name", training.training_title);
      formData.append("description", training.description);
      //formData.append("category", training.category.label);
      formData.append("start_date", training.start_date);
      formData.append("end_date", training.end_date);
      formData.append("workload_hour", training.workload_hour);
      formData.append("color", training.color);
      formData.append("Activity_score", pontuacaoAtividade.value);
      formData.append("Completion_score", pontuacaoConclusao.value);
      formData.append("Certificate", emitir.value ? "Active" : "Disactive");
      formData.append("status", status.value == true ? 1 : 0);

      const courseId = route.query.courseId;
      const trainingId = route.query.trainingId;
      var isEmpty = false;

      for (var member in training) {
        if (training[member] == null) isEmpty = true;
      }

      if (isEmpty) {
        $q.notify({
          message: "Você precisa preencher todos os campos obrigatórios.",
          color: "red-9",
          position: "top",
        });
        loading.value = false;
        return;
      }

      if (trainingId == null) {
        await trailDataServices
          .addTraining(courseId, formData)
          .then((response) => {
            loading.value = false;
            router.push({
              name: "training",
              query: {
                id: courseId,
              },
            });
          })
          .catch((error) => {
            $q.notify({
              message: error.response.data.toString(),
              color: "red-9",
              position: "top",
            });
            loading.value = false;
          });
      } else {
        await trailDataServices
          .editTraining(courseId, trainingId, formData)
          .then((response) => {
            loading.value = false;
            router.push({
              name: "training",
              query: {
                id: courseId,
              },
            });
          })
          .catch((error) => {
            $q.notify({
              message: error.response.data.toString(),
              color: "red-9",
              position: "top",
            });
            loading.value = false;
          });
      }
    }

    function validateDate(val, type, start_date = null, end_date = null) {
      const regex = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
      const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
      const formattedFirstDayOfYear = date.formatDate(firstDayOfYear, "MM-DD");
      const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);
      const formattedlastDayOfYear = date.formatDate(lastDayOfYear, "MM-DD");
      const formattedVal = date.formatDate(val, "MM-DD");

      let validate = date.isBetweenDates(
        formattedVal,
        formattedFirstDayOfYear,
        formattedlastDayOfYear
      );
      let message =
        "Por favor verifique a sua data passada para não ocorrer nenhum erro";

      if (!regex.test(val)) {
        validate = false;
        message = "A data passada está em um formato incoreto";
      }

      if (val.length === 10) {
        if (type === "start" && validate) {
          if (end_date) {
            if (val > end_date) {
              validate = false;
              message = "A sua data inicial não pode ser depois da data final";
            }
          }
          return new Promise((resolve, reject) => {
            resolve(validate || message);
          });
        }

        if (type === "end" && validate) {
          if (start_date) {
            if (val < start_date) {
              validate = false;
              message = "A sua data final não pode ser anterior a data inicial";
            }
          }
          return new Promise((resolve, reject) => {
            resolve(validate || message);
          });
        }
      }
    }

    async function GetTraining(courseId, trainingId) {
      if (trainingId == null) return null;

      var dataTraining = await trailDataServices.getTraining(
        courseId,
        trainingId
      );

      console.log(dataTraining.data.activity_score);
      const startDateformattedString = date.formatDate(
        dataTraining.data.start_date,
        "YYYY-MM-DD"
      );
      const endDateformattedString = date.formatDate(
        dataTraining.data.end_date,
        "YYYY-MM-DD"
      );

      training.training_title = dataTraining.data.training_title;
      training.description = dataTraining.data.description;
      //training.category = dataTraining.data.category;
      training.start_date = startDateformattedString;
      training.end_date = endDateformattedString;
      training.workload_hour = dataTraining.data.workload_hour;
      pontuacaoAtividade.value = dataTraining.data.activity_score;
      pontuacaoConclusao.value = dataTraining.data.completion_score;
      emitir.value = dataTraining.data.certificate == "Active" ? true : false;
      status.value = dataTraining.data.status == 1 ? true : false;
      imageUrl.value = url_aws_bucket + dataTraining.data.cover_image;
    }

    function _alertNotify(message) {
      $q.notify({
        message: message,
        color: "red-9",
        position: "top",
      });
    }

    return {
      breadcrumbs,
      imageUrl,
      status,
      emitir,
      courseId,
      listCategories,
      showDialogCategory,
      refFileInput,
      pontuacaoAtividade,
      pontuacaoConclusao,
      sendEventAddImage,
      sendEventRemoveImage,
      onEventFilePicked,
      validateAndAddTraining,
      loading,
      training,
      addNewCategory,
      validateDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 80vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .content-thumb-training {
      height: 100%;
      flex: 1;

      .thumb-training {
        border-radius: 10px;

        .q-img {
          border-radius: 10px;
        }
      }

      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }

      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }

    .content-form-training {
      height: 100%;
      flex: 1;

      .data-inputs {
        display: flex;
        flex-direction: column;
      }

      .form-input {
        .title-input-form {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
        }
      }
    }

    @media (min-width: 800px) {
      flex-direction: row;

      .content-thumb-training {
        flex: 1;
      }

      .content-form-training {
        flex: 2;

        .data-inputs {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}
</style>
